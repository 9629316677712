<template>
  <div class="pageF6Color">
    <headgobackVue :iCustomizdde="true">
      <template #default>
        <span>
          {{ $fanyi("箱子货物信息") }}
        </span>
      </template>
    </headgobackVue>
    <div class="cartCon">
      <div v-for="(item, index) in lists" :key="index">
        <div v-for="(listItem, listIndex) in item.box_data" :key="listIndex">
          <div
            v-for="(freighItem, freighIndex) in listItem.freight_detail"
            :key="freighIndex"
          >
            <div
              v-for="(
                order_detaItem, order_detaIndex
              ) in freighItem.order_detail"
              :key="order_detaIndex"
              class="shopsBox"
            >
              <h2>
                <span>{{ freighItem.order_sn }}</span>
                <span>
                  <img
                    :src="require('@/assets/icon/dingdanfanhao.png')"
                    alt=""
                  />
                  {{ $fanyi("番号") }}：#{{ order_detaItem.sorting }}</span
                >
              </h2>
              <div class="goodsBox">
                <div class="productPurchaseInfo">
                  <div class="productInfo">
                    <van-image
                      lazy-load
                      class="productImage"
                      :src="order_detaItem.pic"
                      @click="$lookImg(order_detaItem.pic)"
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                    <div>
                      <h3>
                        <!-- 未完成 商品名称没有 -->
                        {{ order_detaItem.goods_title }}
                      </h3>
                      <div
                        class="productAttribute"
                        @click="openDetailSheet(order_detaItem)"
                      >
                        <p>
                          <span
                            v-for="(
                              detailItem, detailIndex
                            ) in order_detaItem.detail"
                            :key="detailIndex"
                          >
                            {{ detailItem.value }};
                          </span>
                        </p>
                        <img
                          class="arrow-down"
                          :src="require('@/assets/icon/heisejiantou.png')"
                          alt=""
                        />
                      </div>
                      <div class="quantityAndAmount">
                        <div class="amount">
                          <b>
                            {{
                              $fun.RMBNumSegmentation(
                                Number(order_detaItem.confirm_price)
                              )
                            }}元</b
                          >(
                          {{
                            $fun.JPYNumSegmentation(
                              $fun.ceil(
                                Number(order_detaItem.confirm_price) *
                                  $store.state.exchangeRate
                              )
                            )
                          }}円)
                        </div>
                        <span class="num"
                          >X{{ order_detaItem.confirm_num }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="customerNote">
                    {{ $fanyi("装箱数量") }}： {{ order_detaItem.in_box_num }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
import headgobackVue from "@/components/headGoBack";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const lists = ref([]); //货物信息列表
//------------------------ methods -------------------------------------
const porderBoxDetail = () => {
  proxy.$api
    .porderBoxDetail({
      porder_sn: proxy.$route.query.id,
    })
    .then((res) => {
      ////console.log('porderBoxDetail',res)
      if (res.code != 0)
        return proxy.$message.error(proxy.$fanyi(res.data.msg));
      //接下来的操作
      lists.value = [res.data.logistics_data[proxy.$route.boxNum || 0]];
    });
};
//------------------------ pageLoad -------------------------------------
porderBoxDetail();
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.cartCon {
  padding: 30px 30px;
  .shopsBox {
    padding: 30px 30px 5px;
    background: #ffffff;
    border-radius: 6px;
    margin-bottom: 15px;
    h2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;
      img {
        width: 26px;
        height: 26px;
        margin-right: 7px;
      }
      span {
        font-size: 28px;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .goodsBox {
      display: flex;
      margin-bottom: 24px;

      .productPurchaseInfo {
        .productInfo {
          display: flex;
          margin-bottom: 30px;
          .productImage {
            flex: 0 0 180px;
            height: 180px;
            margin-right: 20px;
            border-radius: 6px;
          }
          > div {
            flex: 1;
          }
          h3 {
            margin-top: -4px;
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 15px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .productAttribute {
            width: 350px;
            height: 42px;
            background: #ffffff;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            margin-bottom: 10px;
            color: #999999;
            p {
              line-height: 42px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 24px;

              span {
                font-size: 24px;
              }
            }
            .arrow-down {
              width: 12px;
              height: 7px;
              line-height: 1;
              font-weight: 700;
              color: #333333;
              margin-left: auto;
            }
          }
          .quantityAndAmount {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 42px;

            .amount {
              font-size: 24px;
              color: #b4272b;
              b {
                font-size: 28px;
                font-weight: 600;
              }
            }
            .num {
              font-size: 20px;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .customerNote {
          width: 630px;
          height: 56px;
          background: #fafafa;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          padding: 10px 29px;
          display: flex;
          font-size: 24px;
          align-items: center;
        }
      }
    }
  }
}
</style>
